import React from 'react';

import Logo from './components/Logo';

import styles from './DvdScreensaver.module.css';

const DvdScreensaver = () => {
  return (
    <div className={styles.root}>
      <Logo />
    </div>
  );
};

export default DvdScreensaver;
